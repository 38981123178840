/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StateSpecificValues {
    None = 'None',
    CaMotorCarrierNumber = 'CaMotorCarrierNumber',
    NmStateMcIdNumberNmprcNumber = 'NmStateMcIdNumberNmprcNumber',
    OhOs32InsuredsTelephoneNumber = 'OhOs32InsuredsTelephoneNumber',
    OkPinEntityNumberOrDotNumber = 'OkPinEntityNumberOrDotNumber',
    PaUtilityCommissionNumber = 'PaUtilityCommissionNumber',
    TnFein = 'TnFein',
    TxCertificateNumber = 'TxCertificateNumber',
    VaFein = 'VaFein',
    WiFederalIdNumber = 'WiFederalIdNumber'
}

export function StateSpecificValuesFromJSON(json: any): StateSpecificValues {
    return StateSpecificValuesFromJSONTyped(json, false);
}

export function StateSpecificValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateSpecificValues {
    return json as StateSpecificValues;
}

export function StateSpecificValuesToJSON(value?: StateSpecificValues | null): any {
    return value as any;
}

