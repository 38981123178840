/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import useFooterFormState from "./data/useFooterFormState";
import EmailInput from "./email-input";
import ChevronDownIcon from "./icons/chevron-down";
import NumericInput from "./numeric-input";
import TextInput from "./text-input";

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  footerSection: {
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.main,
  },
  divider: {
    background: theme.palette.secondary.light,
  },
  accordianContainer: {
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      display: "flex",
    },
    "& .footer-accordion-text": {
      color: theme.palette.secondary.contrastText,
    },
  },
  accordion: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  accordianSummary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: "65%",
  },
  accordianSummaryContainer: {
    width: "100%",
    justifyContent: "center",
    flexGrow: 0,
    textAlign: "center",
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "0.75rem 0 1rem 2.4rem",
  },
  contactInfo: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
  },
  successMessage: {
    color: theme.palette.success.main,
    marginTop: "1rem",
  },
  form: {
    width: "100%",
    backgroundColor: theme.palette.secondary.light,
    "& .footer-accordion-text label": {
      color: theme.palette.secondary.main,
    },
    padding: "2rem",
    borderRadius: "5px",
    "& input": {
      backgroundColor: theme.palette.common.white,
      borderRadius: "4px",
    },
  },
  caps: {
    textTransform: "uppercase",
    height: "40px",
    paddingTop: "13px",
  },
  policies: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    minHeight: "86px",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
    padding: "0.75rem 0",
    "& a": {
      textDecoration: "none",
      textTransform: "uppercase",
      color: theme.palette.secondary.contrastText,
    },
  },
  legalTextContainer: {
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      display: "flex",
    },
  },
  legalText: {
    color: theme.palette.secondary.light,
    padding: "1.25rem",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  icon: {
    color: theme.palette.secondary.contrastText,
    height: "40px",
    width: "auto",
  },
  needHelpText: {
    paddingTop: "1rem",
  },
  link: {
    color: theme.palette.secondary.contrastText,
    textDecoration: "underline",
  },
  reactOutSection: {
    paddingLeft: "3rem",
  },
}));

enum Fields {
  name = "name",
  email = "email",
  phone = "phone",
}

export type FormProps = {
  [Fields.name]: string;
  [Fields.email]: string;
  [Fields.phone]: string;
};

type Props = {
  isLmdUi?: boolean;
  accordianSummaryAriaLable?: string;
  onlyCarrierHQ?: boolean;
  needHelpText?: string;
  reachOutText?: string;
  privacyText?: string;
  termsText?: string;
  legalText?: string;
  emailLabel?: string;
  defaultEmail?: string;
  phoneLabel?: string;
  defaultPhone?: string;
  nameLabel?: string;
  defaultName?: string;
  termsLink?: string | null;
  privacyLink?: string | null;
  phoneNumber?: string;
  phoneNumberAriaLabel?: string;
  emailAddressAriaLabel?: string;
  contactUsAt?: string;
  or?: string;
  emailAddress?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const Footer: React.FC<Props> = ({
  accordianSummaryAriaLable,
  isLmdUi = false,
  onlyCarrierHQ = false,
  needHelpText,
  reachOutText,
  privacyText,
  termsText,
  legalText,
  emailLabel,
  phoneLabel,
  nameLabel,
  termsLink,
  privacyLink,
  phoneNumber,
  contactUsAt,
  emailAddress,
  phoneNumberAriaLabel,
  emailAddressAriaLabel,
  or,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const formik = useFormikContext<FormProps>();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [footerFormOpen, setFooterFormOpen] = useFooterFormState();

  return (
    <footer className={styles.footer}>
      <Grid container>
        <Grid item xs={12} md={4} className={styles.accordianContainer}>
          <Grid container direction="column">
            <Grid item xs={12} className={classNames(styles.contactInfo, styles.needHelpText)}>
              <Typography variant="body1" className="footer-accordion-text">
                {needHelpText}
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.contactInfo}>
              <Typography variant="body1" className="footer-accordion-text">
                {contactUsAt}
                <Link aria-label={`${phoneNumberAriaLabel}`} className={styles.link} href={`tel:${phoneNumber}`}>
                  <>{phoneNumber}</>
                </Link>
                {or}
                <Link aria-label={`${emailAddressAriaLabel}`} className={styles.link} href={`mailto:${emailAddress}`}>
                  <>{emailAddress}</>
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Accordion expanded={footerFormOpen} className={classNames(styles.footerSection)}>
                <Grid container className={styles.accordianSummaryContainer}>
                  {isLmdUi ? (
                    <AccordionSummary
                      id="footer-form"
                      className={styles.accordianSummary}
                      onClick={() => setFooterFormOpen(!footerFormOpen)}
                      data-testid="accordion-summary"
                      aria-label="Footer Form Accordion"
                    >
                      <div className={styles.reactOutSection}>
                        <Grid container direction={"row"}>
                          <Grid item>
                            <Typography
                              aria-label={accordianSummaryAriaLable}
                              variant="h4"
                              className={classNames("footer-accordion-text", styles.caps)}
                            >
                              <>{reachOutText}</>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ChevronDownIcon className={styles.icon} />
                          </Grid>
                        </Grid>
                      </div>
                    </AccordionSummary>
                  ) : (
                    <AccordionSummary
                      id="footer-form"
                      className={styles.accordianSummary}
                      onClick={() => setFooterFormOpen(!footerFormOpen)}
                      expandIcon={<ChevronDownIcon className={styles.icon} />}
                      data-testid="accordion-summary"
                      aria-label="Footer Form Accordion"
                    >
                      <div>
                        <Typography variant="h4" className={classNames("footer-accordion-text", styles.caps)}>
                          <strong>{reachOutText}</strong>
                        </Typography>
                      </div>
                    </AccordionSummary>
                  )}
                </Grid>
                <AccordionDetails className={styles.accordion}>
                  <form className={styles.form} onSubmit={(res) => formik.setStatus({ res })}>
                    <TextInput
                      fullWidth
                      required
                      label={nameLabel}
                      placeholder="Name"
                      type="text"
                      className="footer-accordion-text"
                      id={Fields.name}
                      name={Fields.name}
                      value={formik.values[Fields.name]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched[Fields.name] && Boolean(formik.errors[Fields.name])}
                      helperText={formik.touched[Fields.name] && formik.errors[Fields.name]}
                    />
                    <EmailInput
                      fullWidth
                      required
                      label={emailLabel}
                      placeholder="example@email.com"
                      className="footer-accordion-text"
                      id={Fields.email}
                      name={Fields.email}
                      value={formik.values[Fields.email]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
                      helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
                    />
                    <NumericInput
                      fullWidth
                      format="(###)###-####"
                      required
                      label={phoneLabel}
                      placeholder="(123) 456-7890"
                      className="footer-accordion-text"
                      id={Fields.phone}
                      name={Fields.phone}
                      value={formik.values[Fields.phone]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched[Fields.phone] && Boolean(formik.errors[Fields.phone])}
                      helperText={formik.touched[Fields.phone] && formik.errors[Fields.phone]}
                    />
                    <Button disabled={!formik.isValid} onClick={() => formik.handleSubmit()} variant="contained">
                      Submit
                    </Button>

                    {formik.submitCount > 0 && (
                      <Typography className={styles.successMessage}>
                        Thank you, your information has been submitted.
                      </Typography>
                    )}
                  </form>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
        {mobileView && (
          <Grid item xs={12}>
            <Divider variant="middle" classes={{ root: styles.divider }} />
          </Grid>
        )}
        {!onlyCarrierHQ && (
          <>
            <Grid item xs={12} md={4} className={classNames(styles.footerSection, styles.policies)}>
              <Typography variant="h4" data-testid="privacy">
                <Link target="_blank" href={privacyLink || ""}>
                  {privacyText}
                </Link>
              </Typography>
              <Typography variant="h4" data-testid="terms">
                <Link target="_blank" href={termsLink || ""}>
                  {termsText}
                </Link>
              </Typography>
            </Grid>
            {mobileView && (
              <Grid item xs={12}>
                <Divider variant="middle" classes={{ root: styles.divider }} />
              </Grid>
            )}
            <Grid item xs={12} md={4} className={styles.legalTextContainer}>
              <Typography variant="subtitle1" component="h5" className={styles.legalText} data-testid="legal">
                {legalText}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </footer>
  );
};

export default Footer;
