/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PrimaryOperationType {
    Agriculture = 'Agriculture',
    AutoTransporter = 'Auto Transporter',
    BulkDryBulk = 'Bulk - Dry Bulk',
    BulkTankChemicalFoodGrade = 'Bulk/Tank - Chemical/Food Grade',
    Contractor = 'Contractor',
    DryFreightLTL = 'Dry Freight LTL',
    DryFreightTL = 'Dry Freight TL',
    Dumping = 'Dumping',
    Flatbed = 'Flatbed',
    FoodGrainDelivery = 'Food/Grain Delivery',
    Intermodal = 'Intermodal',
    LoggingOperations = 'Logging Operations',
    MobileHomeToters = 'Mobile Home Toters',
    NotOtherwiseSpecifiedAllOther = 'Not Otherwise Specified / All Other',
    OilGasFieldOperations = 'Oil/Gas Field Operations',
    RefrigeratedReefer = 'Refrigerated (Reefer)',
    SpecializedCarriers = 'Specialized Carriers',
    TankFuelOilPetroleum = 'Tank - Fuel Oil Petroleum',
    WasteOrDriveAway = 'Waste Or Drive Away',
    Drayage = 'Drayage'
}

export function PrimaryOperationTypeFromJSON(json: any): PrimaryOperationType {
    return PrimaryOperationTypeFromJSONTyped(json, false);
}

export function PrimaryOperationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryOperationType {
    return json as PrimaryOperationType;
}

export function PrimaryOperationTypeToJSON(value?: PrimaryOperationType | null): any {
    return value as any;
}

